.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-left: 20%;
  margin-right: 20%;
  border-bottom: 1px solid #e0eef0;
  color: #999;
  z-index: 10000;
}

.header__logo {
  margin-right: auto;
  object-fit: contain;
  height: 50px;
  cursor: pointer;
}

.header__nav {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 20px;
}
.header__nav li {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
.header__nav__active {
  color: #000066;
}
.header__acc {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__acc > a > button {
  font-size: 14px;
  font-weight: 600;
  border: 0;
  color: #000066;
  background-color: transparent;
  cursor: pointer;
}
.header__acc button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: #000066;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.header__menu {
  color: #000066;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
.sidebar {
  position: fixed;
  z-index: 10;
  top: -100%;
  right: 0;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  width: 100%;
  height: max-content;
  padding: 20px calc(20%);
  transition: all 1s ease-in-out;
}
.sidebar--active {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  width: 100%;
  height: max-content;
  padding: 20px calc(20%);
  transition: all 1s ease-in-out;
}
.sidebar__header {
  width: 100%;
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid white;
}
.sidebar__header button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: #000066;
  background-color: transparent;
  padding: 10px 15px;
  border-radius: 20px;
  margin-left: auto;
  cursor: pointer;
}
.sidebar__nav {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 20px;
  margin: 20px 0;
}
.sidebar__nav li {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #000066;
}
.sidebar__acc {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sidebar__acc > a > button {
  font-size: 14px;
  font-weight: 600;
  border: 0;
  color: #000066;
  background-color: white;
  cursor: pointer;
}
.sidebar__acc button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: #000066;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .header {
    padding: 2.5% 5%;
    margin: 0;
  }
  .header__nav {
    display: none;
  }
  .sidebar--active {
    padding: 5% 5%;
  }
  .header__acc button {
    display: none;
  }
  .header__acc > a > button {
    display: none;
  }
}
