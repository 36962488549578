.home {
}
.home__section__one {
  padding: 5% 20%;
  min-height: 60vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.home__section__xx {
  background: #e2e8f1;
  width: max-content;
  display: flex;
  align-items: center;
}
.static-text {
  font-size: 45px;
  font-weight: 600;
  color: #000066;
}
.dynamic-text {
  font-size: 45px;
  font-weight: 900;
  color: #000066;
}
.wrapper {
  display: flex;
  align-items: center;
}
.wrapper .static-text {
  width: max-content;
}
.wrapper .dynamic-text {
  margin-left: 10px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
}
.dynamic-text li {
  list-style-type: none;
  color: #ff6600;
  font-weight: 600;
  animation: slide 14s steps(4) infinite;
  position: relative;
  top: 0;
}
@keyframes slide {
  100% {
    top: -180px;
  }
}
.dynamic-text li span {
  position: relative;
}
.dynamic-text li span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  border-left: 2px solid #e2e8f1;
  padding: 2px 2px;
  background: #e2e8f1;
  animation: typing 3.5s steps(8) infinite;
}

@keyframes typing {
  50% {
    left: 100%;
    margin: 0 -5px 0 5px;
  }
  100% {
    left: 0%;
    margin: 0 -5px 0 5px;
  }
}
.home__section__one__start {
  width: 50%;
}
.home__section__one__start > h1 {
  font-size: 45px;
  font-weight: 600;
  color: #000066;
}
.home__section__one__start > p {
  font-size: 14px;
  line-height: 25px;
  width: 85%;
  font-weight: 400;
  color: #999;
  margin-top: 30px;
  margin-bottom: 30px;
}
.home__section__one__start > span {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.home__section__one__start > span > button {
  padding: 10px 15px;
  border: 0;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  background: white;
  color: #000066;
  cursor: pointer;
}
.home__section__one__start > span > button:last-of-type {
  color: white;
  background: #000066;
}
.home__section__one__end {
  width: 50%;
  display: flex;
  justify-content: end;
}
.home__section__one__end > img {
  object-fit: cover;
  height: 400px;
  width: 500px;
  border-radius: 10px;
}
.home__section__one__amala {
  padding: 1% 0;
  display: flex;
  align-items: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}
.slider-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.slider-content {
  display: flex;
}
.slider-content.normal {
  animation: scroll-normal 10s linear infinite;
}

.slider-content.reverse {
  animation: scroll-reverse 10s linear infinite;
}

.slider-item {
  text-align: center;
  margin: 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  color: #000066;
  font-weight: 600;
}
.slider-item > img {
  height: 30px;
  width: 30px;
  object-fit: cover;
}

/* Keyframes for normal scrolling animation */
@keyframes scroll-normal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Keyframes for reverse scrolling animation */
@keyframes scroll-reverse {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.home__section__egusi {
  padding: 2.5% 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__section__egusi > h1 {
  font-size: 20px;
  color: #000066;
  font-weight: 600;
  width: 550px;
  text-align: center;
}
.home__section__egusi__wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.home__section__egusi__div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  width: 20%;
}
.home__section__egusi__div > h3 {
  font-size: 45px;
  font-weight: 700;
  color: #000066;
  text-align: center;
}
.home__section__egusi__div > p {
  font-size: 14px;
  line-height: 20px;
  color: #000066;
  text-align: center;
}
.home__section__dodo {
  padding: 2.5% 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}
.home__section__dodo > h1 {
  width: 550px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #000066;
}
.home__section__dodo__wrap {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}
.home__section__dodo__stick {
  width: 50%;
  display: flex;
  justify-content: end;
}
.home__section__dodo__stick > img {
  object-fit: contain;
  height: 400px;
  width: 400px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.home__section__dodo__text {
  width: 50%;
}
.home__section__dodo__text > div {
  width: 100%;
  margin-bottom: 80px;
}
.home__section__dodo__text > div > h1 {
  margin-bottom: 20px;
  color: #000066;
  font-weight: 600;
  font-size: 30px;
}

.home__section__dodo__text > div > p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  color: #000066;
  overflow-wrap: break-word; /* Break long words if necessary */
  word-wrap: break-word;
  margin-bottom: 20px;
}
.home__section__dodo__text > div > ul {
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  color: #000066;
  overflow-wrap: break-word; /* Break long words if necessary */
  word-wrap: break-word;
  margin-bottom: 20px;
  margin-left: 5%;
}

.home__section__fufu {
  padding: 5% 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f7fa;
}
.home__section__fufu > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #000066;
  text-align: center;
}
.home__section__fufu > p {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  width: 600px;
  text-align: center;
  color: #aaa;
}
.home__section__fufu__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.home__section__fufu__div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid white;
  border-radius: 10px;
  width: 30%;
}
.home__section__fufu__div > h3 {
  font-size: 18px;
  font-weight: 600;
}
.home__section__fufu__div > p {
  font-size: 12px;
  line-height: 20px;
  color: #999;
}
.home__section__fufu__div > img {
  object-fit: cover;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.home__section__eba {
  padding: 5% 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__section__eba > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #000066;
}
.home__section__eba > p {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  width: 600px;
  text-align: center;
  color: #aaa;
}
.home__section__eba__faq__wrap {
  margin-top: 20px;
  width: 600px;
  padding: 20px 20px;
  border-radius: 10px;
  border: 1px solid #e0eef0;
}
.lp__faq {
  padding: 10px 0;
  border-bottom: 1px solid #e0eef0;
}
.lp__faq__question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  padding: 10px 0;
}

.lp__faq__question > h1 {
  font-size: 13px;
  color: #999;
}
.lp__faq__question > span {
  display: flex;
  align-items: center;
  gap: 20px;
}
.lp__arrow {
  color: #000066;
  transition: transform 0.25s ease-out;
}
.lp__arrow.open {
  transform: rotateZ(90deg);
}
.lp__faq__answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 0;
}
.lp__faq__answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 0;
}
.lp__faq__answer > p {
  line-height: 25px;
  font-size: 13px;
  margin: 10px 0;
  color: #555;
}
.lp__faq__answer.open {
  max-height: 200px;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}
.home__section__end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 5% 20%;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  color: #000066;
}
.home__section__end > h1 {
  font-size: 35px;
  font-weight: 600;
  color: #000066;
  width: 35%;
  text-align: center;
}
.home__section__end > button {
  font-size: 15px;
  font-weight: 600;
  border: 0;
  color: white;
  background-color: #000066;
  padding: 10px 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.tonal__buttons {
  padding: 10px 15px;
  border: 0;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

@media all and (max-width: 768px) {
  .home__section__end {
    padding: 5% 5%;
  }
  .home__section__end > h1 {
    font-weight: 700;
    font-size: 25px;
    width: 100%;
  }
  .home__section__eba {
    padding: 5% 5%;
  }
  .home__section__eba > p {
    width: 100%;
  }
  .home__section__eba__faq__wrap {
    width: 100%;
  }
  .home__section__one {
    padding: 5% 5%;
    flex-direction: column;
  }
  .home__section__one__start {
    width: 100%;
  }
  .home__section__one__end {
    width: 100%;
    justify-content: start;
  }
  .home__section__one__start > p {
    width: 100%;
  }
  .home__section__one__end > img {
    object-fit: contain;
    height: auto;
    width: 100%;
  }
  .home__section__fufu {
    padding: 5% 5%;
  }
  .home__section__fufu > p {
    width: 100%;
  }
  .home__section__fufu__wrap {
    flex-direction: column;
  }
  .home__section__fufu__div {
    width: 100%;
  }
  .home__section__egusi {
    padding: 5% 5%;
  }
  .home__section__egusi > h1 {
    width: 100%;
    text-align: start;
  }
  .home__section__egusi__wrap {
    flex-direction: column;
  }
  .home__section__egusi__div {
    width: 100%;
  }
  .home__section__dodo {
    padding: 5% 5%;
  }
  .home__section__dodo > h1 {
    width: 100%;
  }
  .home__section__dodo__wrap {
    display: flex;
    flex-direction: column;
  }
  .home__section__dodo__stick {
    width: 100%;
  }
  .home__section__dodo__text {
    width: 100%;
    padding: 0 5%;
  }
}
