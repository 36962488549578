.about__section__one {
  padding: 2.5% 20%;
  /* background: #5b279d; */
  background: #000066;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.about__section__one > h1 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 30px;
  width: 400px;
  text-align: center;
}
.about__section__one > p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  overflow-wrap: break-word; /* Break long words if necessary */
  word-wrap: break-word;
  width: 750px;
  text-align: center;
}
.about__section__two {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.about__section__two__start {
  padding: 2.5% 0;
  padding-left: 20%;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.about__section__two__start > h1 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 25px;
  width: 400px;
}
.about__section__two__start > p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  overflow-wrap: break-word; /* Break long words if necessary */
  word-wrap: break-word;
  width: 100%;
}
.about__section__two__end {
  width: 35%;
  display: flex;
  justify-content: end;
}
.about__section__two__end > img {
  object-fit: cover;
  width: 100%;
}
.about__section__three {
  padding: 2.5% 20%;
  /* background-image: linear-gradient(to top, #cd9cf2 0%, #f6f3ff 100%); */
  background: #e2e8f1;
  /* color: #5b279d; */
  color: #000066;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.about__section__three > h1 {
  font-weight: 600;
  font-size: 30px;
  width: 400px;
  text-align: center;
}
.about__section__three > p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  overflow-wrap: break-word; /* Break long words if necessary */
  word-wrap: break-word;
  width: 750px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .about__section__one,
  .about__section__three {
    padding: 7.5% 5%;
  }
  .about__section__one > p,
  .about__section__three > P {
    width: 100%;
  }
  .about__section__two {
    flex-direction: column;
  }
  .about__section__two__start {
    width: 100%;
    padding: 5% 5%;
  }
  .about__section__two__end {
    width: 100%;
  }
}
