.service__section__one {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000066;
}
.service__section__one > h1 {
  color: white;
  font-size: 40px;
  font-weight: 600;
}
.service__section__two {
  padding: 2.5% 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service__section__two > p {
  font-size: 20px;
  line-height: 35px;
  font-weight: 300;
  width: 700px;
  text-align: center;
}
.service__section__three {
  margin: 2.5% 20%;
  padding: 2.5% 0;
  border: 10px solid #e2e8f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.service__section__three > h2 {
  color: #000066;
  font-size: 30px;
  line-height: 45px;
  font-weight: 600;
  margin-bottom: 10px;
}
.service__section__three__wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 20px;
  text-align: center;
}
.service__section__three__wrap > div {
  width: 22.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.service__section__three__wrap > div > h2 {
  color: #000;
  font-size: 16px;
  line-height: 45px;
  font-weight: 700;
  margin-bottom: 10px;
}
.service__section__three__wrap > div > p {
  color: #444;
  font-size: 16px;
  line-height: 25px;
  font-weight: 200;
}
.service__section__four {
  padding: 2.5% 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e2e8f1;
}
.service__section__four > h2 {
  color: #444;
  font-size: 22px;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 10px;
  width: 700px;
  text-align: center;
}
.service__section__four__wrap {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 20px;
  text-align: center;
}
.service__section__four__wrap > div {
  width: 23.5%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  background: white;
  border-radius: 5px;
  padding: 20px 10px;
  text-align: start;
}
.service__section__four__wrap > div > p {
  color: #444;
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;
}
.service__section__four__wrap__icon {
  width: 20%;
  font-size: 30px;
  color: #000066;
}
@media all and (max-width: 768px) {
  .service__section__one {
    min-height: 30vh;
    padding: 5% 5%;
  }
  .service__section__two {
    padding: 5% 5%;
  }
  .service__section__two > p {
    width: 100%;
  }
  .service__section__three__wrap > div {
    width: 100%;
  }
  .service__section__three {
    margin: 5% 0%;
    padding: 5% 0;
  }
  .service__section__four {
    padding: 5% 5%;
  }
  .service__section__four > h2 {
    width: 100%;
  }
  .service__section__four__wrap {
    padding: 20px 0;
  }
  .service__section__four__wrap > div {
    width: 100%;
  }
  .service__section__four__wrap__icon {
    width: 15%;
    font-size: 30px;
    color: #000066;
  }
}
